<template>
  <div>
    <div class="barra"></div>
    <div class="row justify-content-center text-center">
      <div class="col-">
        <p>{{ message }}</p>
        <br /><br />
      </div>
      <div class="w-100"></div>
      <router-link :to="{ name: 'Courses' }" class="col- cIcon">
        <i class="fas fa-list-ol"></i>
      </router-link>
      <router-link :to="{ name: 'Favorites' }" class="col- cIcon">
        <i class="fas fa-heart"></i>
      </router-link>
      <router-link :to="{ name: 'Profile' }" class="col- cIcon">
        <i class="fas fa-user"></i>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "PartialsErrors",
  props: {
    message: String,
  },
};
</script>

<style>
.barra {
  height: 100px;
}
.cIcon{
		background: #ffffff;
		border-radius: 50%;
		width: 4rem;
		height: 4rem;
		color: #007bff;
		margin: 10px;
		line-height: 4rem;
	}
	.box_access{
		height: 40vh
	}
</style>
